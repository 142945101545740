import { GetComponentListVariables } from '../graphql/index_types.2';
import { groupComponents, mergePages, normalizeComponent } from '../utils/component';
import { useGetComponentListInfiniteQuery } from './queries/useGetComponentListInfiniteQuery';

export function useComponentList(params: GetComponentListVariables) {
  const {
    data,
    error,
    isFetching: is_fetching,
    fetchNextPage,
    hasNextPage: has_next_page,
  } = useGetComponentListInfiniteQuery(params);
  const components = groupComponents(mergePages(data?.pages ?? []).map((component) => normalizeComponent(component)));
  const category_list = data?.pages[0].category_list ?? [];

  return {
    error,
    is_fetching,
    fetchNextPage,
    has_next_page,
    components,
    category_list,
  };
}
