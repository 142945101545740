import { useUBL } from '@common/log-manager';

export type StoreHomeNavigationType = 'shop_detail' | 'shop_category_detail';

export type StoreHomeObjectSection = 'zonly' | 'best' | 'shop_collection_display' | 'all';

interface StoreHomePageviewData {
  is_opened: boolean;
  category_id?: string;
}

interface StoreHomeInformationPageviewData {
  sns_list: string;
}

export interface StoreHomeUBLBanner {
  id: string;
  type: string;
  url: string;
  index: number;
}

export interface StoreHomeUBLCategory {
  id: string;
  name: string;
  index: number;
}

export interface StoreHomeUBLCatalog {
  id: string;
  index: number;
}

export interface StoreHomeInformationUBLSnsItem {
  name: string;
  url: string;
  index: number;
}

export const useStoreHomeUBL = (shop_id: string) => {
  const tracker = useUBL();

  const pageviewUBL = (navigation: StoreHomeNavigationType, page_url: string, data: StoreHomePageviewData) => {
    tracker({
      category: 'pageview',
      navigation,
      navigation_sub: { shop_id, page_url },
      data,
    });
  };

  const bannerClickUBL = async (banner: StoreHomeUBLBanner) => {
    tracker({
      category: 'click',
      navigation: 'shop_detail',
      navigation_sub: { shop_id },
      object_type: 'banner',
      object_id: banner.id,
      object_idx: banner.index,
      object_url: banner.url,
      data: {
        banner_type: banner.type,
      },
    });
  };

  const couponDownloadClickUBL = () => {
    tracker({
      category: 'click',
      navigation: 'shop_detail',
      navigation_sub: { shop_id },
      object_type: 'button',
      object_id: 'seller_coupon',
    });
  };

  const categoryClickUBL = (category: StoreHomeUBLCategory) => {
    tracker({
      category: 'click',
      navigation: 'shop_detail',
      navigation_sub: { shop_id },
      object_type: 'category',
      object_id: category.id,
      object_idx: category.index,
      data: {
        category: category.name,
      },
    });
  };

  const informationPageviewUBL = (data: StoreHomeInformationPageviewData) => {
    tracker({
      category: 'pageview',
      navigation: 'shop_info_detail',
      navigation_sub: { shop_id },
      data,
    });
  };

  const informationSnsLinkClickUBL = (sns_item: StoreHomeInformationUBLSnsItem) => {
    tracker({
      category: 'click',
      navigation: 'shop_info_detail',
      navigation_sub: { shop_id },
      object_type: 'button',
      object_id: sns_item.name,
      object_idx: sns_item.index,
      object_url: sns_item.url,
    });
  };

  const informationMoreModelInfoClickUBL = () => {
    tracker({
      category: 'click',
      navigation: 'shop_info_detail',
      navigation_sub: { shop_id },
      object_type: 'button',
      object_id: 'see_more_model_info',
    });
  };

  const zigzinFilterClickUBL = (is_selected: boolean) => {
    tracker({
      category: 'click',
      navigation: 'shop_detail',
      navigation_sub: { shop_id },
      object_type: 'button',
      object_section: 'sub_quick_filter',
      object_id: 'ZIGZIN',
      data: {
        is_selected,
      },
    });
  };

  return {
    pageviewUBL,
    bannerClickUBL,
    couponDownloadClickUBL,
    categoryClickUBL,
    informationPageviewUBL,
    informationSnsLinkClickUBL,
    informationMoreModelInfoClickUBL,
    zigzinFilterClickUBL,
  };
};
