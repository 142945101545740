import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { RequestContext } from '@common/api-request';

import { getComponentList } from '../../graphql/index.2';
import { GetComponentList, GetComponentListVariables } from '../../graphql/index_types.2';

export interface UseGetComponentListInfiniteQueryData extends NonNullable<GetComponentList['shop_ux_component_list']> {}

export function getComponentListInfiniteQueryOptions(
  params: GetComponentListVariables,
  context?: RequestContext,
): UseInfiniteQueryOptions<UseGetComponentListInfiniteQueryData> {
  return {
    queryKey: ['GetComponentList', params],
    queryFn: async ({ pageParam }) => {
      const { data } = await getComponentList({ ...params, after_id: pageParam }, { context });
      const {
        after_id = null,
        has_next_page = false,
        item_list = [],
        category_list = [],
      } = data.shop_ux_component_list ?? {};
      return { after_id, has_next_page, item_list, category_list };
    },
    getNextPageParam: (lastPage) => (lastPage.has_next_page ? lastPage.after_id : undefined),
    keepPreviousData: true,
    ...(Boolean(params.sub_filter_id_list?.length) && { refetchOnWindowFocus: true, staleTime: 0 }),
  };
}

export function useGetComponentListInfiniteQuery(params: GetComponentListVariables) {
  return useInfiniteQuery(getComponentListInfiniteQueryOptions(params));
}
