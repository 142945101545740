import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

import { Autoplay, Pagination, Swiper, SwiperProps, SwiperSlide } from '@common/carousel/swiper';
import { isToss, useZigzag } from '@common/device-manager';
import { Gray_Light_White, Typography } from '@common/styles';
import {
  BannerGroup,
  BannerGroupBannerList,
  HEADER_HEIGHT,
  ShopUxBannerLinkType,
  StoreHomeUBLBanner,
} from '@domains/storehome-common';

import { BannerCount } from './BannerCounter';
import { SWIPER_STYLE } from './swiper';

interface Props {
  banner_group: BannerGroup;
  is_preview?: boolean;
  sendUBL?: (banner: StoreHomeUBLBanner) => void;
}

const DEFAULT_DELAY = 4000;
export const ShopMainBanner: FC<Props> = ({ banner_group, is_preview, sendUBL }) => {
  const router = useRouter();
  const { query } = router;
  const { auto_rolling_interval, is_auto_rolling, banner_list } = banner_group;
  const is_single_banner = banner_list?.length === 1;
  const is_app = useZigzag(false);
  const [active_index, setActiveIndex] = useState(0);

  const renderBannerItem = (
    { id, image_url, title, link_type, link_button }: BannerGroupBannerList,
    isSwiper: boolean,
    index?: number,
  ) => {
    return (
      <RatioWrap>
        <BannerItem
          onClick={() => {
            const link = link_button?.link_url;
            const moveTo = () => {
              if (link) {
                if (link_type === null) {
                  const match = /store-pick\/detail\?id=(\d+(?:,\d+)+)/.exec(link);
                  if (match && match[1]) {
                    const store_pick_url = `/store-pick/detail?id=${match[1]}`;
                    router.push(store_pick_url);
                    return store_pick_url;
                  }
                  // TODO: BE 링크 변경 이후 제거 line:57-64
                  const match_legacy = /.+url=(.+)/.exec(link);
                  if (match_legacy) {
                    const storepick_url = match_legacy[1];
                    if (storepick_url) {
                      window.open(decodeURIComponent(storepick_url), '_blank');
                      return storepick_url;
                    }
                  }
                }

                if (link_type === ShopUxBannerLinkType.PRODUCT) {
                  // PDP로 이동
                  const match = /.+url=.+products\/(\w+)/.exec(link);
                  if (match && match[1]) {
                    const catalog_product_url = isToss()
                      ? `/toss-shopping/products/${match[1]}`
                      : `/catalog/products/${match[1]}`;
                    router.push(catalog_product_url);
                    return catalog_product_url;
                  }
                }

                if (link_type === ShopUxBannerLinkType.CATEGORY) {
                  // 스토어홈 카테고리 상세 화면으로 이동
                  const match = /.+category_id=(\w+)/.exec(link);
                  if (match && match[1]) {
                    const category_url = `/${query.main_domain}/category/${match[1]}`;
                    router.push(category_url);
                    return category_url;
                  }
                }

                if (link_type === ShopUxBannerLinkType.COLLECTION) {
                  // 스토어홈 카테고리 상세 화면으로 이동
                  const match = /.+collection\/(\w+)/.exec(link);
                  if (match && match[1]) {
                    const collection_url = `/${query.main_domain}/collection/${match[1]}`;
                    router.push(collection_url);
                    return collection_url;
                  }
                }
              }

              return '';
            };

            const move_to_url = moveTo();
            sendUBL?.({
              id,
              type: !link_type ? 'STOREPICK' : (link_type as string),
              url: move_to_url,
              index: index || 0,
            });
          }}
        >
          {image_url && <Image src={image_url} alt={title ?? ''} />}
          <Dim />
          <Title className={Typography.HEAD_24_BOLD} isSwiper={isSwiper}>
            {title}
          </Title>
        </BannerItem>
      </RatioWrap>
    );
  };

  const renderImageBanner = () => {
    if (!banner_list) {
      return null;
    }

    return renderBannerItem(banner_list[0], false);
  };

  const renderSwiperBanner = () => {
    const delay = auto_rolling_interval ? auto_rolling_interval * 1000 : DEFAULT_DELAY;

    const options: SwiperProps = {
      modules: [Autoplay, Pagination],
      loop: is_auto_rolling,
      autoplay: {
        delay,
        disableOnInteraction: false,
      },
      onSlideChange: (e) => {
        setActiveIndex(e.realIndex);
      },
    };
    return (
      <Swiper {...options}>
        {<BannerCount active_index={active_index} total_count={banner_list?.length ?? 0} />}
        {banner_list &&
          banner_list.map((bannerItem, index) => (
            <SwiperSlide key={`main-${bannerItem.name}-${index}`}>
              {renderBannerItem(bannerItem, true, index)}
            </SwiperSlide>
          ))}
      </Swiper>
    );
  };

  return (
    <BannerSwiper is_app={!!is_app} is_preview={Boolean(is_preview)}>
      <RadiusWrap>{is_single_banner ? renderImageBanner() : renderSwiperBanner()}</RadiusWrap>
    </BannerSwiper>
  );
};

interface SwiperStyledProp {
  isSwiper?: boolean;
}

const BannerSwiper = styled.div<{ is_app: boolean; is_preview: boolean }>`
  ${SWIPER_STYLE}
  max-width: 600px;
  width: 100%;
  height: auto;
  top: ${({ is_app, is_preview }) => (is_app || is_preview ? 0 : HEADER_HEIGHT)}px;
  padding: 16px 16px 0 16px;

  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: 25px;

      .swiper-pagination-bullet {
        background-color: #ffffff;
        opacity: 0.5;
        width: 6px;
        height: 6px;
        margin: 0 2px;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
`;

const RadiusWrap = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const RatioWrap = styled.div`
  position: relative;
  padding: 0 0 60% 0;
  overflow: hidden;
  width: 100%;
  height: 0;
`;

const BannerItem = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

const Dim = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
  pointer-events: none;
`;

const Title = styled.strong<SwiperStyledProp>`
  position: absolute;
  padding: 0 16px;
  max-width: ${(props) => (props.isSwiper ? 'calc(100% - 70px)' : '100%')};
  max-height: 50px;
  bottom: 16px;
  font-size: 18px;
  line-height: 25.2px;
  color: ${Gray_Light_White};
  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 0.3s 0.1s ease-in-out,
    opacity 0.4s 0.1s linear;
  overflow: hidden;
  white-space: pre;

  .swiper-slide-active & {
    opacity: 1;
    transform: translateY(0);
  }
`;
